import React from "react";
import Link from "next/link";

function CmsButton({ component, page_id='' }) {
  if (component.options.url != "") {
    return (
      <div className={`link`} id={`${component.id ?? ""}`}>
        <Link href={component.options.url} target={component.options.url.startsWith('/') ? '_self' : '_blank' } className="externalBtn">
            <span>
              {component.options.link_text != ""
                ? component.options.link_text
                : component.options.url}
            </span>
            {/* <svg
              width="12"
              height="21"
              viewBox="0 0 12 21"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1.67118 20.975C1.24475 20.975 0.818111 20.8122 0.493132 20.4867C-0.157867 19.8357 -0.157867 18.7811 0.493132 18.1301L7.64996 10.9756L0.493132 3.81984C-0.157867 3.16884 -0.157867 2.11422 0.493132 1.46323C1.14413 0.812226 2.19875 0.812226 2.84975 1.46323L11.1825 9.79602C11.8335 10.447 11.8335 11.5016 11.1825 12.1526L2.84975 20.4854C2.52425 20.8135 2.09772 20.975 1.67118 20.975Z"
                fill="white"
              />
            </svg> */}
        </Link>
      </div>
    );
  }
}

export default CmsButton;
